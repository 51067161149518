import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../helper/api";
import { toast } from "react-toastify";

export const getFunctions = createAsyncThunk("user/getFunctions", async (payload) => {

  const response = await api.post("admin/functions/get", payload);
 
  return response.data;
});

const getFunctionsSlice = createSlice({
  name: "user",
  initialState: {
    data:null,
    loading: false,
    error: null,
  },
//   middleware: (getDefaultMiddleware) =>
//   getDefaultMiddleware({
//     serializableCheck: {
//       ignoredPaths: ["payload.headers"],
//     },
//   }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFunctions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFunctions.fulfilled, (state, action) => {
   
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getFunctions.rejected, (state, action) => {
        state.loading = false;
     
        toast.error(action.error.message);
      });
  },
});

export default getFunctionsSlice.reducer;
