import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../helper/api";
import { toast } from "react-toastify";

export const updateFunction = createAsyncThunk("user/updateFunction", async (payload) => {

  const response = await api.put("admin/functions/update", payload);
 
  return response.data;
});

const updateFunctionSlice = createSlice({
  name: "user",
  initialState: {
    data:null,
    loading: false,
    error: null,
  },
 
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFunction.fulfilled, (state, action) => {
   
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateFunction.rejected, (state, action) => {
        state.loading = false;
      
        toast.error(action.error.message);
      });
  },
});

export default updateFunctionSlice.reducer;
