import React, { lazy } from "react";
import Login from "../pages/auth/login";
const Signup = lazy(() => import("../pages/auth/signup"));
const HomeScreen = lazy(() => import("../pages/home-screen"));
const Profile = lazy(() => import("../pages/profile"));
const ClientListing = lazy(() => import("../pages/client-list/index"));
const Settings = lazy(() => import("../pages/settings/index"));
const GameManagement = lazy(() => import("../pages/game-management/index"));
const PayoutAdjustment = lazy(() => import("../pages/payout-adjustment/index"));
const EditPayout = lazy(() => import("../pages/payout-adjustment/edit-payout"));
const CommissionManagement = lazy(() => import("../pages/commission/index"));
const AdminWiseCommission = lazy(() =>
  import("../pages/commission/admin-wise-commission")
);
const AdminGameHistory = lazy(() =>
  import("../pages/admin-game-history/index")
);
const UserLocationManagement = lazy(() => import("../pages/user-location"));
const Functions = lazy(() => import("../pages/functions/index"));

export const openRoutes = [
  {
    layout: <Login />,
    path: "/",
    name: "Login",
    isAuthProtected: false,
  },
  {
    layout: <Signup />,
    path: "/signup",
    name: "Signup",
    isAuthProtected: false,
  },
];
export const superadminprivateRoutes = [
  {
    layout: <HomeScreen />,
    path: "/home-screen",
    name: "HomeScreen",
    isAuthProtected: true,
  },
  {
    layout: <Profile />,
    path: "/profile",
    name: "Profile",
    isAuthProtected: true,
  },

  {
    layout: <ClientListing />,
    path: "/client-listing",
    name: "ClientListing",
    isAuthProtected: true,
  },
  {
    layout: <Settings />,
    path: "/settings",
    name: "Settings",
    isAuthProtected: true,
  },
  {
    layout: <GameManagement />,
    path: "/game-management",
    name: "GameManagement",
    isAuthProtected: true,
  },
  {
    layout: <PayoutAdjustment />,
    path: "/payout-adjustment",
    name: "PayoutAdjustment",
    isAuthProtected: true,
  },
  {
    layout: <EditPayout />,
    path: "payout-adjustment/payout-edit",
    name: "EditPayout",
    isAuthProtected: true,
  },
  {
    layout: <CommissionManagement />,
    path: "commission-management",
    name: "CommissionManagement",
    isAuthProtected: true,
  },
  {
    layout: <AdminWiseCommission />,
    path: "admin-commission-management",
    name: "AdminWiseCommission",
    isAuthProtected: true,
  },
  {
    layout: <AdminGameHistory />,
    path: "admin-game-history",
    name: "AdminGameHistory",
    isAuthProtected: true,
  },
  {
    layout: <UserLocationManagement />,
    path: "user-location",
    name: "UserLocationManagement",
    isAuthProtected: true,
  },
  {
    layout: <Functions />,
    path: "admin-functions-management",
    name: "Functions",
    isAuthProtected: true,
  }
];

