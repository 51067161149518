import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../helper/api";
import { toast } from "react-toastify";

export const createFunction = createAsyncThunk("user/createFunction", async (payload) => {

  const response = await api.post("admin/functions/create", payload);
 
  return response.data;
});

const createFunctionsSlice = createSlice({
  name: "user",
  initialState: {
    data:null,
    loading: false,
  },
//   middleware: (getDefaultMiddleware) =>
//   getDefaultMiddleware({
//     serializableCheck: {
//       ignoredPaths: ["payload.headers"],
//     },
//   }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(createFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(createFunction.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.error.message);
      });
  },
});

export default createFunctionsSlice.reducer;
