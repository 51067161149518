import React, { useEffect } from "react";
import Dashboard from "../assets/images/svgs/dashboard.svg";
import Profile from "../assets/images/svgs/profile.svg";
import Client from "../assets/images/svgs/people-fill.svg";
import Setting from "../assets/images/svgs/gear-fill.svg";
import History from "../assets/images/svgs/hourglass-bottom.svg";

export function useOutsideAlerter(ref, open, setOpen) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      const logoClass = event?.target?.className;
      if (logoClass && typeof logoClass !== "object") {
        if (logoClass?.indexOf("toggleIcon") !== -1) {
          setOpen(!open);
        } else if (ref?.current && !ref.current?.contains(event?.target)) {
          setOpen(open);
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const setCookies = (key, value) => {
  document.cookie = key + "=" + (value || "") + "; path=/";
};

export const getCookies = (key) => {
  const value = ("; " + document.cookie).split(`; ${key}=`).pop().split(";")[0];
  return value;
};

export const deleteCookies = (name) => {
  console.log("delete");
  document.cookie =
    name +
    "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=Strict; Secure";
};

export let sidebarDrawerInit = false;

// export const handleSidebarDrawerInit = () => {
//   console.log(430,sidebarDrawerInit )
//   sidebarDrawerInit = !sidebarDrawerInit;
//   console.log(433,sidebarDrawerInit )
//   return sidebarDrawerInit;
// };

export const handleSidebarDrawerInit = () => {
  sidebarDrawerInit = !sidebarDrawerInit;
  return sidebarDrawerInit;
};

export const roles = ["super_admin", "admin"];
export const super_user_location_access = [
  {
    label: "User Location List",
    url: "user-location",
    img: <img src={Client} alt={"profile"} />,
  },
]
export const superadminsideBarList = [
  {
    label: "Dashboard",
    url: "home-screen",
    img: <img src={Dashboard} alt={"dashboard"} />,
  },
  {
    label: "Profile",
    url: "profile",
    img: <img src={Profile} alt={"profile"} />,
  },

  {
    label: "Client Listing",
    url: "client-listing",
    img: <img src={Client} alt={"Client"} />,
  },
  {
    label: "Settings",
    url: "settings",
    img: <img src={Setting} alt={"setting"} />,
  },
  {
    label: "Game Management",
    url: "game-management",
    img: <img src={Client} alt={"profile"} />,
  },
  {
    label: "Payout Adjustment",
    url: "payout-adjustment",
    img: <img src={Client} alt={"profile"} />,
  },
  {
    label: "GGR Management",
    url: "commission-management",
    img: <img src={Client} alt={"profile"} />,
  },
  {
    label: "Admin wise GGR",
    url: "admin-commission-management",
    img: <img src={Client} alt={"profile"} />,
  },
  {
    label: "Functions",
    url: "admin-functions-management",
    img: <img src={Client} alt={"profile"} />,
  },
  
];
export const adminsideBarList = [
  {
    label: "Dashboard",
    url: "home-screen",
    img: <img src={Dashboard} />,
  },
  {
    label: "Profile",
    url: "profile",
    img: <img src={Profile} alt={"profile"} />,
  },

  {
    label: "Client Listing",
    url: "client-listing",
    img: <img src={Client} alt={"profile"} />,
  },
  {
    label: "Settings",
    url: "settings",
    img: <img src={Setting} alt={"Setting"} />,
  },

  {
    label: "Admin Game History",
    url: "admin-game-history",
    img: <img src={History} alt={"History"} />,
  },
];

export const RiskLevel = [
  { label: "Classic", value: "classic" },
  { label: "Low", value: "low" },
  { label: "Medium", value: "medium" },
  { label: "High", value: "high" },
];
export const CommissionType = [
  { label: "Profit", value: "profit" },
  { label: "Bet", value: "bet" },
  { label: "Fixed", value: "fixed" },
];
