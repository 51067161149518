import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../helper/api";
import { toast } from "react-toastify";

export const deleteFunction = createAsyncThunk("user/deleteFunction", async (payload) => {

  const response = await api.post("admin/functions/delete", payload);
 
  return response.data;
});

const deleteFunctionSlice = createSlice({
  name: "user",
  initialState: {
    data:null,
    loading: false,
    error: null,
  },
 
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFunction.fulfilled, (state, action) => {
   
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(deleteFunction.rejected, (state, action) => {
        state.loading = false;
      
        toast.error(action.error.message);
      });
  },
});

export default deleteFunctionSlice.reducer;
